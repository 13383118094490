import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { InboxOutlined, DeleteOutlined, DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { message, Upload, Modal, Alert } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useForm, Controller } from 'react-hook-form';
import Moment from 'moment';
import { addCandidateAttachment, deleteCandidateAttachment, downloadCandidateAttachment, editCandidateAttachment, getAssociatedCandidateFiles } from '../../../services/CandidateAttachmentsService';
import { canSelectedCandidateBeUpdated } from '../../../services/CandidateService';
import { activeUserPermissions } from '../../../services/UsersService';

const types = ["application/msword", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

const { Dragger } = Upload;

export function AttachmentsCard(props) {

    const { register, getValues, setValue, control, watch, formState } = useForm();
    const isCurrentResume = watch("fileInfo.currentResume", props.drivers);
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [modalInfo, setModalInfo] = useState({
        show: false,
        file: {}
    });

    const [attachments, setAttachments] = useState([]);
    const [isPdf, setIsPdf] = useState(true);
    const [hasCurrentResumeChanged, setHasCurrentResumeChanged] = useState(false);
    const [fileText, setFileText] = useState(null);

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change' && name === 'fileInfo.currentResume') {
                setHasCurrentResumeChanged(true);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        getAssociatedCandidateFiles(props.selectedCandidate.candidates.candidates_ID).then(data => {
            manipulateList(data);
        }).catch(error => {
            toast.error("Something went wrong")
        });
    }, []);

    function manipulateList(list) {
        var newList = list.map(item => ({
            ...item,
            uid: item.candidateAttachments_ID,
            name: item.attachmentName,
            status: 'done'
        }));

        setAttachments(newList);
    }

    async function openAddModal(file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = (e.target.result)
            if (file.type === 'application/msword') setFileText(text);
            //alert(text)
        };
        reader.readAsText(file)

        setModalInfo({
            show: true,
            file
        });
    }

    function handleCloseModal() {
        setModalInfo({
            show: false,
            file: {}
        });

        setValue('fileInfo', {
            attachmentDescription: '',
            currentResume: false
        });
    }

    function handleSubmit() {
        let { fileInfo } = getValues();
        fileInfo.currentResume = fileInfo.currentResume === "true" || fileInfo.currentResume === 1 || fileInfo.currentResume === true ? true : false;

        if (fileInfo.candidateAttachments_ID) {
            toast.loading("Updating attachment...", { key: 'loading' });
            let formdata = new FormData();
            formdata.append('attachments_ID', fileInfo.candidateAttachments_ID);
            formdata.append('fileDescription', fileInfo.attachmentDescription);
            formdata.append('currentResume', fileInfo.currentResume);
            formdata.append('candidates_ID', fileInfo.candidates_ID);

            editCandidateAttachment(formdata).then(data => {
                if (data) {
                    toast.success("Attachment has been updated");

                    manipulateList(data);

                    handleCloseModal();

                    toast.destroy('loading');

                    if (hasCurrentResumeChanged) {
                        props.reloadResume();
                    }
                    
                }

            }).catch(error => {
                toast.destroy('loading');

                toast.error("Something went wrong");
            });
        } else {
            toast.loading("Uploading attachment...", { key: 'loading' });
            let formdata = new FormData();
            formdata.append('file', modalInfo.file);
            formdata.append('candidates_ID', props.selectedCandidate.candidates.candidates_ID);
            formdata.append('fileDescription', fileInfo.attachmentDescription);
            formdata.append('currentResume', fileInfo.currentResume);
            if (modalInfo.file.type === 'application/msword') formdata.append('rtfContent', fileText);

            addCandidateAttachment(formdata).then(data => {
                if (data) {
                    toast.success("Attachment has been added");

                    manipulateList(data);

                    handleCloseModal();

                    toast.destroy('loading');
                    if (hasCurrentResumeChanged) {
                        props.reloadResume();
                    }
                }

            }).catch(error => {
                toast.destroy('loading');
                toast.error("Something went wrong");
            });
        }
    }

    function deleteFile(e, file) {
        let postdata = {};
        postdata.Id = file.uid;

        confirm({
            title: "You are about to delete this file.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    deleteCandidateAttachment(postdata).then(data => {
                        if (data) {
                            manipulateList(data);
                            toast.success("Attachement has been deleted");

                            if (file.currentResume === true || file.currentResume === 1) {
                                props.clearResume();
                            }
                        } else {
                            toast.error("Something went wrong");
                        }
                        
                    }).catch(error => {
                        toast.error("Something went wrong");
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function downloadFile(e, file, showOriginal = false) {

        let postdata = {};
        postdata.Id = file.uid;
        postdata.showOriginal = showOriginal;
        downloadCandidateAttachment(postdata).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = showOriginal ? file.originalAttachmentName : file.name;
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(error => {
            toast.error("Something went wrong");
        });

    }

    function editFile(e, file) {
        if (e.target.id === 'edit') {
            setValue('fileInfo', file);
            setModalInfo({
                file,
                show: true
            });
        }
    }

    return (
        <>
            {isActive &&
                <>
                    <Dragger
                    accept=".pdf, .docx, .rtf"
                    disabled={!canUpdate}
                        beforeUpload={(file) => {
                            if (!types.includes(file.type)) {
                                message.error(`${file.name} is not of proper type`);
                                return false;
                            } else {
                                if (file.type !== 'application/pdf') {
                                    setIsPdf(false);
                                } else {
                                    setIsPdf(true);
                                }
                                return true
                            }
                        }}
                        customRequest={(info) => {
                            openAddModal(info.file);
                        }}
                        fileList={attachments}

                        itemRender={(originNode, file, currFileList) => (
                            <>
                                <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text" id="edit">
                                    <div className="ant-upload-list-item-info" id="edit" onClick={(e) => editFile(e, file)}>
                                        <span className="ant-upload-span" id="edit">
                                            <div className="ant-upload-text-icon" id="edit">
                                                <PaperClipOutlined id="edit" />
                                            </div>
                                            <span className="ant-upload-list-item-name" id="edit" title={file.name}>
                                                {file.name} {file.currentResume ? <i className="text-success">(Current Resume)</i> : null}
                                                <br />
                                                <i className="ms-2">Added on {Moment(file.dateAdded).format('L')} at {Moment(file.dateAdded).format('LT')}</i>
                                            </span>
                                            <span className="ant-upload-list-item-card-actions" id="edit">
                                                <button title="Download file" type="button" className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn" id="download" onClick={(e) => downloadFile(e, file)}>
                                                    <DownloadOutlined className="icon-md" id="download" />
                                                </button>
                                                {uPermissions.users_ID && uPermissions.deleteAttachments &&
                                                    <button title="Remove file" type="button" className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn" id="delete" onClick={(e) => deleteFile(e, file)}>
                                                        <DeleteOutlined className="icon-md text-danger" id="delete" />
                                                    </button>
                                                }
                                            </span>
                                        </span>
                                    </div>

                                </div>
                                {file.originalAttachmentName &&
                                    <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text" id="edit">
                                        <div className="ant-upload-list-item-info">
                                            <span className="ant-upload-span" id="edit">
                                                <span className="ant-upload-list-item-name ms-5" id="edit" title={file.originalAttachmentName}>
                                                    {file.originalAttachmentName} <em>(Original File)</em>
                                                </span>
                                                <span className="ant-upload-list-item-card-actions" id="edit">
                                                    <button title="Download file" type="button" className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn" id="download" onClick={(e) => downloadFile(e, file, true)}>
                                                        <DownloadOutlined className="icon-md" id="download" />
                                                    </button>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                }
                            </>
                        )}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag a file to upload</p>
                    </Dragger>

                    <Modal
                        open={modalInfo.show}
                        title="Save Document Details"
                        footer={[
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleCloseModal}>Close</button>
                                </div>
                            </div>
                        ]}
                    >
                        {(isCurrentResume && !isPdf) ? <Alert showIcon type="warning" message="Warning: A PDF is preferred when uploading and parsing a resume. This resume will be converted to a PDF, but an original copy will be maintained as well." /> : null}
                        <label>Attachment Name:</label>
                        <p>{modalInfo.file.name}</p>

                        <div className="col-12">
                            <label>Document Description</label>
                            <textarea className="ant-input" {...register('fileInfo.attachmentDescription')} />
                        </div>

                        {(isCurrentResume || !modalInfo.file.candidateAttachments_ID || modalInfo.file.attachmentName.includes(".pdf")) ?
                            <div className="col-12">
                                <label className="d-block">Current Resume?</label>
                                <input type="checkbox" className="custom-checkbox" {...register('fileInfo.currentResume')} />
                            </div>
                            :
                            null
                        }
                    </Modal>
                </>
            }
        </>
    );
}