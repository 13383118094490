import useSWR, { mutate } from "swr"
import { fetcher } from "./fetcher"

export function useJobs(org_ID, users_ID, placementStatus, pageSize, offset, searchValue, sortBy, sortDirection, showArchived) {
    const { data, error, isLoading, isValidating } = useSWR(placementStatus ? `/api/getJobsWithPlacementsByUser?org_ID=${org_ID}&users_ID=${users_ID}&placementStatus=${placementStatus}&pageSize=${pageSize}&offset=${offset}&searchValue=${searchValue}&sortBy=${sortBy}&sortDirection=${sortDirection}&showArchived=${showArchived}` : null, fetcher)
   
    return {
      data,
      isLoading,
      isError: error,
      isValidating
    }
  }