import { useRecoilState, useRecoilValue } from "recoil";
import { activeWorkflowItemsState, subSpecialties } from "../../services/OrganizationsService";
import { Fragment, useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { Empty, Popover, Skeleton, Table, notification, Progress, Pagination } from "antd";
import { activeExpandCandidateWorkflow, activeUserCandidatesState, candidatePlacementTableParams } from "../../services/CandidateService";
import { MatchPane } from "../Placements/MatchPane";
import { useNavigate } from "react-router-dom";
import { PlacementWorkflowPane } from "../Placements/PlacementWorkflowPane";
import { set, useForm } from "react-hook-form";
import { activeUserInfo, activeUserPermissions } from "../../services/UsersService";

import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "../../swr/fetcher";
import { isItemStillMatch } from "../../helpers/Matcher";
import { cityStateFormatter } from "../../helpers/Location";

export default function CandidatePlacementWorkflow(props) {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate();
    const { register, getValues, setValue, control, watch, formState } = useForm();

    const [activeWorkflowItems, setActiveWorkflowItems] = useRecoilState(activeWorkflowItemsState);
    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);
    const [jobsListName, setJobsListName] = useState('activeJobs');

    const [workflowCandidates, setWorkflowCandidates] = useState(null);
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [candidatesPag, setCandidatesPag] = useState([]);
    const [showCandidates, setShowCandidates] = useState(false);
    const [loading, setLoading] = useState(true);

    const [expandEverything, setExpandEverything] = useRecoilState(activeExpandCandidateWorkflow);
    const [expandedIds, setExpandedIds] = useState([]);

    const [status, setStatus] = useState('Active');

    const [tableParams, setTableParams] = useRecoilState(candidatePlacementTableParams);
    
    const [matchPane, setMatchPane] = useState({ show: false, candidate: null });
    const [placementWorkflowPane, setPlacementWorkflowPane] = useState({ show: false, selectedJob: null, clientName: null, selectedCandidate: null, idx: null, workflowItem: null });

    const key = `/api/GetCandidatesWithPlacementsByUser?org_ID=${uInfo.organizations_ID}&users_ID=${uInfo.users_ID}&placementStatus=${status}&pageSize=${tableParams.pagination.pageSize}&offset=${tableParams.pagination.current - 1}&candidates_ID=${props.showSingleCandidateID || 'null'}`;

    const { data, error, isLoading, isMutating } = useSWR(key, fetcher);

    useEffect(() => {
        if (data) {
            handlePlacementWorkflow();
        } else {
            setLoading(true);
        }
    }, [data]);

    useEffect(() => {
        mutate(key);
    }, [tableParams]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'placementStatus') {
                filterPlacementStatus(value.placementStatus);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function filterPlacementStatus(status) {
        let localJobsListName = '';
        switch (status) {
            case 'Active':
                localJobsListName = 'activeJobs';
                break;
            case 'Inactive':
                localJobsListName = 'inactiveJobs';
                break;
            case 'Terminated':
                localJobsListName = 'terminatedJobs';
                break;
            case 'Completed':
                localJobsListName = 'completedJobs';
                break;
            default:
                localJobsListName = 'activeJobs';
                break;
        }

        setStatus(status);

        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });

        setJobsListName(localJobsListName);
    }

    function getWorkflowTypeByJobsListName(localJobsListName) {
        switch (localJobsListName) {
            case 'inactiveJobs':
                return 'inactiveWorkflow';
            case 'completedJobs':
                return 'completedWorkflow';
            case 'terminatedJobs':
                return 'terminatedWorkflow';
            case 'activeJobs':
            default:
                return 'activeWorkflow';
        }
    }

    function handlePlacementWorkflow(localJobsListName = null) {
        let filteredCandidates = [];

        if (!localJobsListName) { localJobsListName = jobsListName; }

        let workflowType = getWorkflowTypeByJobsListName(localJobsListName);

        if (data[workflowType]?.data?.length > 0) {
            data[workflowType].data.map(iaw => {
                let localIaw = { ...iaw };

                let itemIdx = filteredCandidates.findIndex(fc => fc?.candidates_ID === localIaw.candidates_ID);
                let jobs = {
                    job: { 
                        jobs_ID: localIaw.jobs_ID, 
                        jobTitle: localIaw.jobTitle, 
                        jobType: localIaw.jobType,
                        hideFromSystem: localIaw?.hideFromSystem, 
                        externalJob: localIaw?.hideFromSystem, 
                        jobState: localIaw.jobState, 
                        jobCity: localIaw.jobCity, 
                        visaTypes: localIaw.jobVisaTypes,
                        jobSpecialties_ID: localIaw.jobSpecialties_ID,
                        jobSubSpecialties_ID: localIaw.jobSubSpecialties_ID
                    },
                    workflow: localIaw.workflowItems
                }

                if (itemIdx < 0) {
                    localIaw[localJobsListName] = [jobs];

                    filteredCandidates.push(localIaw);
                } else {
                    (filteredCandidates[itemIdx][localJobsListName] && Array.isArray(filteredCandidates[itemIdx][localJobsListName])) ? filteredCandidates[itemIdx][localJobsListName].push(jobs) : filteredCandidates[itemIdx][localJobsListName] = [jobs];
                }
            });
        }

        let filteredCandidatesFinal = [];
        for (var i = 0; i < filteredCandidates.length; i++) {
            let item = { ...filteredCandidates[i] };
            var isHide = (item[localJobsListName]?.length <= 0) ? true : false;
            item.showJobs = expandedIds.includes(item.candidates_ID) || expandEverything || props.showSingleCandidateID;
            if (!isHide) {
                var idx = filteredCandidatesFinal.findIndex(c => c.candidates_ID === item.candidates_ID);

                if (idx >= 0) {
                    filteredCandidatesFinal[idx] = item
                } else {
                    filteredCandidatesFinal.push(item);
                }
            }
        }

        if (props.showSingleCandidateID) {
            filteredCandidatesFinal = filteredCandidatesFinal.filter(c => c.candidates_ID === props.showSingleCandidateID);
        }

        setFilteredCandidates(filteredCandidatesFinal);
        setCandidatesPag(filteredCandidatesFinal);

        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: data[workflowType].totalRecords,
                current: props.showSingleCandidateID ? 1 : tableParams.pagination.current
            }
        });

        setShowCandidates(true);
        setLoading(false);
        setWorkflowCandidates(data);
    }

    function showSpecificCandidates(e, item, idx) {
        if (e.target.tagName !== 'A') {
            item.showJobs = item.showJobs ? false : true;
            if(item.showJobs) {
                setExpandedIds([...expandedIds, item.candidates_ID]);
            } else {
                let localExpandedIds = [...expandedIds];
                const index = localExpandedIds.indexOf(item.candidates_ID);
                if (index > -1) { // only splice array when item is found
                    localExpandedIds.splice(index, 1); // 2nd parameter means remove one item only
                }
                setExpandedIds(localExpandedIds);
            }
            let candidates = [...candidatesPag];
            candidates[idx] = item;
            setCandidatesPag(candidates);
        }
    }

    function showWorkflowPane(candidate) {
        setMatchPane({ show: true, candidate });
    }

    function showPlacementWorkflow(job, item, idx) {
        var workflowType = getWorkflowTypeByJobsListName(jobsListName);
        let workflowCandidateInQuestion = workflowCandidates[workflowType].data.find(wc => wc.candidates_ID === item.candidates_ID && wc.jobs_ID === job.jobs_ID);

        setPlacementWorkflowPane({
            show: true,
            selectedJob: job,
            clientName: item.clientName,
            selectedCandidate: item,
            idx,
            workflowItem: workflowCandidateInQuestion
        });
    }

    //TODO = Check triggerRefresh method
    function hidePlacementPane() {
        setPlacementWorkflowPane({
            show: false,
            selectedJob: null,
            clientName: null,
            selectedCandidate: null,
            idx: null,
            workflowItem: null
        });
        
        mutate(key);

        notification.info({
            message: `Refreshing data...`,
            description: <div>
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#326085',
                    }}
                    percent={100}
                    showInfo={false}
                    status="active"
                />
            </div>,
            placement: 'bottomRight',
            duration: 2
        });
    }

    function goToJobDashboard(item, job) {
        navigate({
            pathname: `/job-dashboard/${job.job.jobs_ID}`,
            state: { from: 'placementWorkflow' }
        });
    }

    function viewCandidate(candidate) {
        navigate({
            pathname: `/candidate-dashboard/${candidate.candidates_ID}`,
            state: { from: 'placementWorkflow' }
        });
    }

    function fullNameFormatter(cell, row) {
        return (
            <span>{row.candidateFirstName} {row.candidateLastName}</span>
        );
    }

    function linkFormatter(cell, item) {
        return (
            <>
                {!props.isArchived && <span className="a float-right mx-1" onClick={() => showWorkflowPane(item)}> Match Jobs</span>}
                {showViewEntity(item) ?
                    <>{!props.showSingleCandidateID && <a className="a float-right mx-1" onClick={() => viewCandidate(item)}> View Candidate</a>}</>
                    :
                    <NoPermissionsPopover />
                }
            </>
        );
    }

    function handleExpandOrCollapse(toExpand) {
        setExpandEverything(toExpand);
        let candidates = [...candidatesPag];
        candidates.map(c => c.showJobs = toExpand);
        setCandidatesPag(candidates);
    }

    const columns = [{
        dataIndex: 'candidateFirstName',
        title: 'Name',
        render: fullNameFormatter
    }, {
        title: 'Actions',
        align: 'right',
        render: linkFormatter
    }];

    //This method determines if a user is seeing a job due to one of their entities being placed in a workflow is also allowed to see the job/candidate not affiliated with them
    const showViewEntity = (entity) => {
        if(uInfo.users_ID === entity?.users_ID || uPermissions?.readJobs === 'company') {
            return true;
        } else {
            return false;
        }
    }

    
    const NoPermissionsPopover = () => {
        return (
            <Popover
                content={
                    <div>
                        You do not have permissions to view this.
                    </div>
                }
            >
                <i className="mx-1 fa-solid fa-lock"></i>
            </Popover>
        );
    }

    const dropdownRender = (item) => {
        return (
            <>
                {item[jobsListName]?.length > 0 &&
                    <>
                        {item[jobsListName].map((job, cidx) => (
                            <div className="row" key={cidx}>
                                <div className="col col-12">
                                    <span>{job.job.jobTitle} &nbsp;</span>
                                    {!isItemStillMatch(item, job.job) &&
                                        <Popover
                                            content={
                                                <div>
                                                    This job is not a full match. Job or Candidate info has changed since creating this placement.
                                                </div>
                                            }
                                        >
                                            <i className="fa-solid fa-asterisk text-danger"></i>
                                        </Popover>
                                    }
                                </div>
                                <div className="col col-12">
                                    {showViewEntity(job.job) ?
                                        <>{!job.job?.hideFromSystem && <span className="a float-right mx-1" onClick={() => goToJobDashboard(item, job.job)}>View Job</span>}</>
                                        :
                                        <NoPermissionsPopover />
                                    }
                                    
                                    {!props.isArchived && <span className="a float-right mx-1" onClick={() => showPlacementWorkflow(job.job, item, cidx)}>View Workflow</span>}
                                    {/* <span className="a float-right mx-1" onClick={() => showRelatedEventsPane(job.job, item)}>View Related Events</span> */}


                                </div>
                                <div className="horizontal-candidates-divider"></div>
                            </div>
                        ))}
                    </>
                }
            </>
        );
    }

    const onShowSizeChange = (current, pageSize) => { 
        setTableParams({ 
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current,
                pageSize
            }
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-3 col-12 mb-5">
                    <label>Filter by Placement Status</label>
                    <select className="ant-input" {...register('placementStatus')}>
                        <option>Active</option>
                        <option value="Inactive">On Hold/Inactive</option>
                        <option>Terminated</option>
                        <option>Completed</option>
                    </select>
                </div>

                <div className="col-lg-3 col-12 align-items-center d-flex">
                    {tableParams.pagination.total >= 0 &&
                        <div className="form-group mx-3 ps-4 d-flex align-items-center">
                            Total:&nbsp;<strong>{tableParams.pagination.total} Candidate(s)</strong>
                        </div>
                    }
                </div>
            </div>

            {(loading || isLoading) ?
                <Skeleton active paragraph={{ rows: 4 }} />
                :
                <>
                    <MediaQuery maxWidth={1223}>
                        {activeWorkflowItems.length > 0 &&
                            <Table
                                rowKey={(record) => record.candidates_ID}
                                dataSource={candidatesPag}
                                columns={columns}
                                size='small'
                                expandable={{
                                    expandedRowRender: (record) => dropdownRender(record),
                                    rowExpandable: (record) => record.candidates_ID !== 'Not Expandable',
                                }}
                            />
                        }
                    </MediaQuery>
                    <MediaQuery minWidth={1224}>
                        {(showCandidates && candidatesPag?.length > 0) ?
                            <>
                                <h4>Candidates with Matched Jobs</h4>
                                <div className="overflow-x-table candidate">
                                    <table className="candidate-table table table-header-rotated table-striped table-hover table-striped-column">
                                        <thead>
                                            <tr>
                                                <th className="row-header">
                                                    <div className="legend">
                                                        <i className="fa-solid fa-check text-success fa-1x"></i> = Completed <br />
                                                        <i className="fa-solid fa-repeat fa-1x"></i> = In Progress <br />
                                                        <i className="fa-regular fa-asterisk text-danger"></i> = <span tooltip="This candidate is missing data">Problem found</span> <br />
                                                        <i className="job-text fa-solid fa-link"></i> = External Job
                                                    </div>
                                                    <p>* Click on <i className="fa-solid fa-plus-circle text-success"></i> to see all matched candidates for a job<br />
                                                        * Click on a candidate to process workflow</p>
                                                    {!expandEverything ? <p className="a" onClick={() => handleExpandOrCollapse(true)}>Expand all jobs</p> : <p className="a" onClick={() => handleExpandOrCollapse(false)}>Collapse all jobs</p>}

                                                </th>
                                                {activeWorkflowItems.map((item, idx) => (
                                                    <th className="rotate-45" key={idx}><div><span>{item.workflowItem}</span></div></th>

                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {candidatesPag.map((item, idx) => (
                                                <Fragment key={idx}>
                                                    <Fragment>
                                                        <tr onClick={(e) => showSpecificCandidates(e, item, idx)}>
                                                            <td className="y-value" key={idx}>
                                                                <span>{(item.showJobs) ? (item[jobsListName]?.length > 0 ? <i className="fa-solid fa-minus-circle text-secondary"></i> : <span />) : (item[jobsListName] && item[jobsListName].length > 0) ? <i className="fa-solid fa-plus-circle text-success"></i> : <span />}&nbsp;</span>
                                                                <span>{item.candidateFirstName} {item.candidateLastName} &nbsp;</span>
                                                                {(item.candidateJobTypes === '' || item.preferredStatesToWork === '' || item.visaTypes_ID === '' || !item.visaTypes_ID) &&
                                                                    <Popover content={<p>This is showing a partial match. This candidate has an incomplete record. Either preferred job types, preferred visa types or preferred states to work is missing.</p>}>
                                                                        <i className="fa-regular fa-asterisk text-danger hover"></i>
                                                                    </Popover>
                                                                }

                                                                {!props.isArchived && <a className="a float-right mx-1" onClick={() => showWorkflowPane(item)}> Match Jobs</a>}
                                                                {showViewEntity(item) ?
                                                                    <>{!props.showSingleCandidateID && <a className="a float-right mx-1" onClick={() => viewCandidate(item)}> View Candidate</a>}</>
                                                                    :
                                                                    <NoPermissionsPopover />
                                                                }
                                                                <br />
                                                                {(item.candidateState || item.candidateCity) &&
                                                                    <small className="ms-4"><em>{cityStateFormatter(item)}</em></small>
                                                                }   
                                                            </td>
                                                            {activeWorkflowItems.map((item, idx) => (
                                                                <td key={idx}>
                                                                    <span className="label label-success">
                                                                        <i className="icon-ok icon-white"></i>
                                                                    </span>
                                                                </td>

                                                            ))}
                                                        </tr>
                                                        {item.showJobs &&
                                                            <Fragment>
                                                                {item[jobsListName]?.length > 0 &&
                                                                    <Fragment>
                                                                        {item[jobsListName].map((job, cidx) => (
                                                                            <tr key={cidx}>
                                                                                <td className="indented-y-value">
                                                                                    {job.externalJob &&
                                                                                        <Popover
                                                                                            content={
                                                                                                <div>
                                                                                                    It is an external job and may not be visible to the system.
                                                                                                </div>
                                                                                            }
                                                                                        >
                                                                                            <i className="job-text fa-solid fa-link me-2"></i>
                                                                                        </Popover>
                                                                                    }
                                                                                    <span>{job.job.jobTitle} &nbsp;</span>
                                                                                    {!isItemStillMatch(item, job.job) &&
                                                                                        <Popover
                                                                                            content={
                                                                                                <div>
                                                                                                    This job is not a full match. Job or Candidate info has changed since creating this placement.
                                                                                                </div>
                                                                                            }
                                                                                        >
                                                                                            <i className="fa-regular fa-asterisk text-danger"></i>
                                                                                        </Popover>
                                                                                    }

                                                                                {!props.isArchived && <a className="a float-right mx-1" onClick={() => showPlacementWorkflow(job.job, item, cidx)}>View Workflow</a>}
                                                                                    
                                                                                {showViewEntity(job) ?
                                                                                    <>{!job?.hideFromSystem && <a className="a float-right mx-1" onClick={() => goToJobDashboard(item, job)}>View Job</a>}</>
                                                                                    :
                                                                                    <NoPermissionsPopover />
                                                                                }
                                                                                    {/* <a className="a float-right mx-1" onClick={() => showRelatedEventsPane(job.job, item)}>View Related Events</a> */}
                                                                                </td>
                                                                                {job.workflow && job.workflow.map((wf, idx) => (
                                                                                    <td className="text-center" key={idx}>
                                                                                        <span className="label label-success" title={wf.placementsWorkflowModel && wf.placementsWorkflowModel.workflowStatus}>
                                                                                            <i className="icon-ok icon-white"></i>
                                                                                            {wf.placementsWorkflowModel ? wf.placementsWorkflowModel.workflowStatus === 'Completed' ? < i className="fa-solid fa-check text-success fa-1x"></i> : <i className="fa-solid fa-repeat fa-1x"></i> : <span />}
                                                                                        </span>
                                                                                    </td>))}

                                                                            </tr>
                                                                        ))}
                                                                    </Fragment>}
                                                            </Fragment>
                                                        }
                                                    </Fragment>

                                                </Fragment>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Pagination
                                        showSizeChanger
                                        onChange={onShowSizeChange}
                                        pageSize={tableParams.pagination?.pageSize}
                                        defaultCurrent={tableParams.pagination?.current}
                                        current={tableParams.pagination?.current}
                                        total={tableParams.pagination?.total}
                                        showTotal={tableParams.pagination?.showTotal}
                                        showQuickJumper
                                        size='default'
                                        className="float-end pagination"
                                    />

                                </div>
                            </>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Placements To Show" />
                        }
                    </MediaQuery>
                </>
            }


            <MatchPane show={matchPane.show} hidePane={() => { setMatchPane({ show: false, candidate: null }); }} entity={matchPane.candidate} from='candidates' placementStatus={status} />
            <PlacementWorkflowPane show={placementWorkflowPane?.show} hidePane={hidePlacementPane} workflowItem={placementWorkflowPane?.workflowItem} job={placementWorkflowPane?.selectedJob} candidate={placementWorkflowPane?.selectedCandidate} clientName={placementWorkflowPane?.clientName} placementStatus={status} />
        </div>
    );
}