import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activeUserInfo, activeUserPermissions } from "../../services/UsersService";
import { archiveClient, canSelectedClientBeDeleted, canSelectedClientBeUpdated, deleteAllClientInformation, getClientDashboardInformation, restoreClient } from "../../services/ClientsService";
import { emptyEvent } from "../../services/EventsService";
import Moment from 'moment';
import { activeEventTypes } from "../../services/OrganizationsService";
import * as ClientCards from './ClientCards/index';
import { EventSlider } from "../Dashboard/EventSlider";
import { toast, confirm } from '@rickylandino/react-messages';
import { Skeleton, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import JobPlacements from "./JobPlacements";

export function ClientDashboard(props) {

    const { clients_ID } = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);
    const eventTypes = useRecoilValue(activeEventTypes);



    const setCanUpdateClient = useSetRecoilState(canSelectedClientBeUpdated);
    const [canDelete, setCanDelete] = useRecoilState(canSelectedClientBeDeleted);

    const [selectedClient, setSelectedClient] = useState(null);

    const [goBackText, setGoBackText] = useState("Return to my clients");

    const [showEventsSlider, setShowEventsSlider] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({ ...emptyEvent });

    useEffect(() => {
        if (clients_ID && uInfo?.users_ID) {
            getClientDashboardInformation(clients_ID, uInfo.users_ID).then(data => {
                setSelectedClient(data);
                setLoaded(true);
            });
        }
    }, [clients_ID, uInfo]);

    useEffect(() => {
        if (selectedClient?.client?.clients_ID && uPermissions?.users_ID) {
            const canUpdate = (uPermissions.updateClients === 'company' || (uPermissions.updateClients === 'personal' && uInfo.users_ID === selectedClient.client.users_ID));
            const canBeDeleted = (uPermissions.deleteClients === 'company' || (uPermissions.deleteClients === 'personal' && uInfo.users_ID === selectedClient.client.users_ID));

            setCanUpdateClient(canUpdate);
            setCanDelete(canBeDeleted);
        } else {
            setCanUpdateClient(false);
        }
    }, [selectedClient, uPermissions]);

    function goBack() {
        navigate("/clients", { replace: true });
    }

    function addEvent() {

        let eventToAdd = {
            events: {
                ...emptyEvent,
                eventDateTime: Moment(new Date),
                eventTypes_ID: eventTypes.find(type => type.eventType === 'To-Do')?.eventTypes_ID
            },
            eventTypes: eventTypes.find(type => type.eventType === 'To-Do')
        };

        setSelectedEvent(eventToAdd);
        setShowEventsSlider(true);
    }

    function handleDeleteClient() {
        confirm({
            title: <span><i className='far fa-trash-alt text-danger icon-md pr-1'></i> &nbsp; You are about to permanently delete this client and all associated data.</span>,
            content: "Are you sure you would like to proceed? This action cannot be undone.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        content: "Deleting this client will also delete all associated data in the system. Are you sure you want to proceed?",
                        okText: "Yes",
                        onOk() {
                            toast.loading("Deleting client...", { key: 'loading' });

                            deleteAllClientInformation(selectedClient.client).then(data => {
                                if (data) {
                                    toast.destroy('loading');
                                    toast.success("Client data has been deleted");

                                    navigate("/clients", { replace: true });
                                } else {
                                    toast.error("Something went wrong");
                                }
                            }).catch(error => {
                                toast.error('Something went wrong');
                            });
                        }
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function handleArchiveClient() {
        confirm({
            title: <span><i className='fas fa-archive text-warning icon-md pr-1'></i> &nbsp; You are about to archive this client.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    toast.loading("Archiving client...", { key: 'loading' });
                    archiveClient(selectedClient.client).then(data => {
                        if (data) {
                            toast.destroy('loading');
                            toast.success("Client has been archived");

                            navigate("/clients", { replace: true });
                        } else {
                            toast.error("Something went wrong");
                        }
                    }).catch(error => {
                        toast.error('Something went wrong');
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function handleRestoreClient() {
        confirm({
            title: <span><i className='far fa-window-restore text-info icon-md pr-1'></i> &nbsp; You are about to restore this client.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    toast.loading("Restoring client...", { key: 'loading' });
                    restoreClient(selectedClient.client).then(data => {
                        toast.destroy('loading');
                        if (data) {
                            toast.success("Client has been restored");

                            navigate("/clients", { replace: true });
                        } else {
                            toast.error("Something went wrong");
                        }
                    }).catch(error => {
                        toast.error('Something went wrong');
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    return (
        <div>
            <div className="horizontal-clients-divider-thick" />

            <div className="row mt-3">
                <div className="col col-lg-4 col-12">
                    <div className="margin-0-10">
                        {selectedClient ?
                            <>
                                <h1>{selectedClient?.client?.name}</h1>

                                <span className="a" onClick={goBack}><i className="fas fa-arrow-left"></i> {goBackText}</span>
                            </>
                            :
                            <Skeleton active />
                        }

                    </div>
                </div>

                <div className="col col-lg-8 col-12 mb-4">
                    {/* <div className="float-start">
                        <button className="ant-btn ant-btn-primary float-left m-1" onClick={addEvent}><i className="fa-solid fa-plus hover" onClick={addEvent}></i> Add Event</button>
                    </div> */}
                    {canDelete &&
                        <>
                            <div className="float-end">
                                <button className="ant-btn ant-btn-danger float-right m-1" onClick={handleDeleteClient}><i className='far fa-trash-alt'></i> &nbsp; Delete this Client</button>
                            </div>

                            {selectedClient?.client?.archive === 1 ?
                                <div className="float-end">
                                    <button className="ant-btn ant-btn-info float-right m-1" onClick={handleRestoreClient}><i className='far fa-window-restore'></i> &nbsp; Restore this Client</button>
                                </div>
                                :
                                <div className="float-end">
                                    <button className="ant-btn ant-btn-warning float-right m-1" onClick={handleArchiveClient}><i className='fas fa-archive'></i> &nbsp; Archive this Client</button>
                                </div>
                            }
                        </>
                    }

                </div>

            </div>

            <div className="horizontal-clients-divider" />

            {loaded ?
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <ClientCards.ContactInformationCard {...props} selectedClient={selectedClient} updateClientInformation={(selectedCli) => setSelectedClient(selectedCli)} />
                        <div className="horizontal-clients-divider" />
                        <ClientCards.SocialMediaCard {...props} selectedClient={selectedClient} updateClientInformation={(selectedCli) => setSelectedClient(selectedCli)} />
                        <div className="horizontal-clients-divider" />
                        <ClientCards.CommentsCard {...props} selectedClient={selectedClient} updateClientInformation={(selectedCli) => setSelectedClient(selectedCli)} />
                        <div className="horizontal-clients-divider" />
                    </div>
                    <div className="col-lg-6 col-12">
                        <JobPlacements clients_ID={selectedClient?.client?.clients_ID} />
                    </div>
                </div>
                :
                <div className="col-lg-6 col-12">
                    <Skeleton active paragraph={{ rows: 10 }} />
                </div>
            }

            <EventSlider clients_ID={selectedClient?.client?.clients_ID || null} showModal={showEventsSlider} hideModal={() => setShowEventsSlider(false)} selectedEvent={selectedEvent} eventTypes={eventTypes} selectedDate={Moment(new Date)} users_ID={uInfo.users_ID} />
        </div>
    );
}