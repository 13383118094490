import useSWR, { mutate } from "swr"
import { fetcher } from "./fetcher"

export function useClients(org_ID, users_ID, pageSize, offset, searchValue, sortBy, sortDirection, showArchived) {
    const { data, error, isLoading, isValidating } = useSWR(`/api/GetClients?org_ID=${org_ID}&users_ID=${users_ID}&pageSize=${pageSize}&offset=${offset}&searchValue=${searchValue}&sortBy=${sortBy}&sortDirection=${sortDirection}&showArchived=${showArchived}`, fetcher)
   
    return {
      data,
      isLoading,
      isError: error,
      isValidating
    }
  }